import React from 'react';
import SubNav from './sub-nav';
import {graphql, useStaticQuery} from "gatsby";

const AccidentSubNav = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  if (data.general.country_code === 'au') {
    return (
      <SubNav urls={{
        'Accident Management Services': '/accident-management/services',
        'SME Fleets': '/accident-management/sme-fleets',
        'Corporate & Government Fleets': '/accident-management/corporate-government-fleets',
        'About Accident Management': '/accident-management/about'
      }}/>
    );
  }
  if (data.general.country_code === 'nz') {
    return (
      <SubNav urls={{
        'Accident Management Services': '/accident-management/services',
        'SME Fleets': '/accident-management/sme-fleets',
        'Corporate & Government Fleets': '/accident-management/corporate-government-fleets',
        'About Accident Management': '/accident-management/about',
        'FAQ': '/accident-management/faqs',
        'Testimonials': '/accident-management/testimonials'
      }}/>
    );
  }
};

export default AccidentSubNav;
