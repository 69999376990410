import React from 'react';
import { Link } from 'gatsby';
import '../assets/styles/components/header.scss';

function links(urls)
{
  const links = [];
  for (let [title, url] of Object.entries(urls)) {
    links.push(
      <li className="nav-list">
        <Link to={urls[title]} className="navigation-link" activeClassName="active">{title}</Link>
      </li>
    );
  }
  return links;
}

const SubNav = ({ urls }) => (
  <ul className="sub-nav">
    {links(urls)}
  </ul>
);

export default SubNav;
