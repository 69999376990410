import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SubNav from '../../components/sub-nav';
import SEO from '../../components/seo';
import SmeImage from '../../images/crash-management/header-sme.png'
import CallCenterGuy from '../../images/crash-management/Call-centre-guy.jpg'
import AccidentSubNav from "../../components/accident-sub-nav";

const AccidentManagementSME = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  if (data.general.country_code === 'nz') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | SME fleet collision repair management services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={SmeImage}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>SME fleet collision repair management services</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <h2>Small to medium enterprises are the life-blood of the New Zealand economy.</h2>
                    <p>Crash Management is an independent kiwi run and operated company and we understand the sector
                      well. We know there are never enough resources or time in the day. Few fleets have the luxury of
                      spare vehicles so business suffers when one of yours is off the road for collision repairs. We
                      also
                      know that managing your own road-side rescues and claims, organising assessing and negotiating
                      with
                      a local panel beating shop is time-consuming and can be frustrating. It isn’t core business for
                      you
                      and it certainly isn’t revenue generating!</p>
                    <p>Crash Management is here to help you get fleet fit. We serve hundreds of SME clients
                      nationwide &nbsp;and can customise a Health &amp; Safety compliant driver support programme to
                      suit
                      your business requirements.</p>
                    <p>Crash Management won the prestigious<strong>&nbsp;Insurance Industry Award 2009 for Innovation of
                      the Year</strong>&nbsp;and can save you time, business disruption and money. We can deliver a
                      best-of-breed accident management service for both company-owned or leased fleets.</p>
                    <h3>Introduction</h3>
                    <p>Crash Management Services provides independent accident management &amp; collision repair
                      services
                      to fleets of all sizes. A small sample of references is provided on our Testimonials page. We have been
                      providing superior service to New Zealand fleets since 2004 and work closely with a range of
                      alliance partners in the car leasing, car manufacturing and insurance broker sectors.</p>
                    <p>Our value proposition is simple – professional fleet management saves clients time, resources and
                      money. Accident and collision repair administration is time-consuming and requires specialised
                      expertise to ensure consistent technical and service quality. It is a non-core activity for most
                      businesses that consumes non-productive time, so is an obvious out-sourcing opportunity for all
                      fleets.</p>
                    <p>In addition to our core accident management offering, we also provide a range of service
                      extension
                      options and can fully customise a programme to suit your unique operational requirements. This
                      will
                      ensure you’re fleet fit and compliant &nbsp;with the Health &amp; Safety at Work Act legislation
                      that apply to all aspects of business including driving risk. Standard Operating Procedures can be
                      developed to suit your fleet requirements and ensure consistency and compliance throughout the
                      country. Our bundled services can be delivered at very competitive rates.</p>
                    <p>If you’d like to know more about Crash Management Services contact us for a confidential
                      no-obligation discussion at <a
                        href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz.</a></p>
                    <h3>Service summary</h3>
                    <p>Crash Management provides a 24/7 nationwide turn-key accident management solution and links
                      seamlessly with other key stakeholders in the supply chain. This includes insurance brokers,
                      assessors, and an extensive network of high-quality certified collision repair specialists that
                      are
                      accepted by all insurance companies. We manage all logistics from the point of impact through to
                      the
                      day of delivery. This reduces administration time for management and drivers, and improves
                      operational efficiency by minimising delays and overall time off the road.</p>
                    <p>Crash Management’s customer satisfaction and service offering is superior to market competitors,
                      and includes provision of free late-model replacement vehicles in most locations. This
                      significantly
                      reduces inconvenience and ensures seamless mobility to minimise business disruption.</p>
                    <ul>
                      <li>24/7 nationwide toll-free service – maximum caller response time 60 seconds</li>
                      <li>Emergency services co-ordination: Police, Ambulance, Fire</li>
                      <li>Salvage &amp; towing co-ordination (cost of towing is billed direct to your insurer)</li>
                      <li>Pro-active timely communications – coordinating all parties including customers, insurance
                        companies, assessors, repairers, and our rental car provider
                      </li>
                      <li>Free courtesy cars in most locations</li>
                      <li>Free rental cars, SUVs, utes &amp; vans for all not-at-fault drivers&nbsp;– <em>subject to the
                        third party’s insurer accepting liability for the accident.</em></li>
                      <li>Co-ordination of quality damage repairs to manufacturers’ specifications, to ensure the
                        on-going
                        safety of the vehicle
                      </li>
                      <li>Daily progress tracking and pro-active status updates to customers</li>
                      <li>Customer log-in access to specialised accident management cloud-based portal for full
                        transparency &amp; permanent record keeping
                      </li>
                      <li>Windscreen/glass claim co-ordination and on-site replacements (workplace or home)</li>
                      <li>All insurance broker &amp; insurance company clients served, except Protecta Insurance</li>
                    </ul>
                    <h3>Management Reports</h3>
                    <p>A wide range of management reporting options is available and can be tailored to suit. This
                      includes simple monthly activity summary and claim cycle-time reports. More complex options
                      include
                      incident data that analyses accident causes, recidivist drivers, and trends.&nbsp; These tools
                      support targeted remedial action that has been proven to reduce accident rates over time,
                      resulting
                      in insurance premium savings. Lower incident rates result in fewer driver injuries, less time off
                      work and reduced business disruption. Information is power.</p>
                    <h3>Health &amp; Safety</h3>
                    <p>A mobile workforce is the back-bone of the business and must be protected. Any vehicle used
                      for&nbsp;business purposes is deemed to be&nbsp;<em>“</em>a place of work<em>”</em>&nbsp;so fleet
                      operations are subject to the H&amp;S obligations imposed by the Act. In addition to
                      identification
                      of any hazard (a motor vehicle/driving) this includes a requirement to identify the probability of
                      accident or harm, or “risk”, and develop appropriate responses. In order to comply any fleet
                      operator must demonstrate best practice in fit-for-purpose vehicle selection and maintenance
                      safety,
                      and develop robust driver safety protocols. Crash Management can help.</p>
                    <h3>Our technical team &amp; systems</h3>


                    <div
                      className="wp-block-columns is-layout-flex wp-container-core-columns-layout-1 wp-block-columns-is-layout-flex">
                      <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                        <p>Our friendly Operation staff “The Crash Team” have extensive insurance or trade-based
                          experience including the collision repair sector. &nbsp;These technical skills combined with
                          extensive in-house training delivers Crash Management “extreme service” every time. Your
                          dedicated Case Manager will be your advocate throughout the end-to-end accident and collision
                          repair experience.</p>


                        <p>State-of-the-art telephone technology and software systems ensure prompt dependable response
                          from the Crash Team. We do not use machine screening, recorded menus or off-shore
                          call-centres.
                          Operational assistance is available during business hours at <a
                            href="mailto:crashteam@crashmanagement.co.nz?Subject=Enquiry%20from%20Crash%20Management%20website">crashteam@crashmanagement.co.nz</a>&nbsp;or
                          0800 2CRASH including after-hours and emergency response.</p>


                        <p>Our processes are cloud-based and real-time so we can capture all the information we require
                          in
                          just a few minutes using client-specific templates to ensure reliability and consistency. This
                          enables us to maximise customer service and response times including dispatch of emergency
                          services, salvage, repairer selection based on known availability, and courtesy car delivery.
                          Our platform ensures clarity, provides a permanent record, is fully transparent and auditable.
                          The new case is uploaded instantly which triggers an automated email notification to all
                          stakeholders.</p>
                      </div>


                      <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                        <img src={CallCenterGuy}/>
                      </div>
                    </div>


                    <h3>Cost</h3>
                    <p>In addition to our core accident management offering, we also provide a range of service
                      enhancements, benefits and reporting packages with the most competitive pricing in the New Zealand
                      market. Talk to us now. We’re eager to understand your unique requirements in order to develop a
                      proposal that will deliver the ideal service solution and ensure your business is fleet fit.</p>
                    <h3>Talk to us now</h3>
                    <p>For a confidential no-obligation discussion about your fleet requirements contact <a
                      href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz</a> now.</p></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
  if (data.general.country_code === 'au') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | SME fleet collision repair management services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={SmeImage}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>SME Fleet Collision Repair Management Services by DingGo</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <h3>Small to medium enterprises are the life-blood of the Australian economy.</h3>
                    <p>
                      DingGo is an independent Australian-owned and operated company specialising in fleet accident
                      management and repair management services. We comprehend the challenges faced by SMEs – the
                      scarcity of resources and the constant race against time. Few fleets have the luxury of spare
                      vehicles, leading to business setbacks when one is sidelined for collision repairs. We understand
                      the hassles of managing roadside rescues, claims, and negotiating with local panel beating shops.
                      It's time-consuming and can be frustrating, detracting from your core business and revenue
                      generation.
                    </p>
                    <p>
                      At DingGo, we're here to assist you in achieving fleet fitness. With a broad clientele of SMEs
                      nationwide, we offer a customisable, health & safety-compliant driver support program tailored
                      to your business needs.
                    </p>
                    <p>Our services save you time, minimise business disruption, and cut costs. Whether you have
                      company-owned or leased fleets, DingGo delivers a top-notch accident management service.</p>
                    <h3>Introduction</h3>
                    <p>
                      DingGo offers independent accident management and collision repair services to fleets of all
                      sizes. Since 2018, we've been providing exceptional service to Australian fleets, collaborating
                      closely with alliance partners in car leasing, car manufacturing, and insurance brokerage.
                    </p>
                    <p>
                      Our value proposition is clear – professional fleet management saves you time, resources, and
                      money. Outsourcing accident and collision repair administration, a non-core activity for most
                      businesses, frees up non-productive time and offers a significant opportunity for all fleets.
                    </p>
                    <p>
                      In addition to core accident management, we provide service extensions and fully customisable
                      programs to ensure your fleet is compliant with health & safety legislation. Standard Operating
                      Procedures tailored to your fleet maintain consistency and compliance nationwide. Our bundled
                      services come at highly competitive rates.
                    </p>
                    <p>
                      For more information about DingGo's services, contact us for a confidential, no-obligation
                      discussion at <a href="sales@DingGo.com.au">sales@DingGo.com.au</a>
                    </p>
                    <h3>Service summary</h3>
                    <p>
                      DingGo provides a nationwide turn-key accident management solution seamlessly integrated with
                      key stakeholders in the supply chain. This includes insurers, brokers, assessors, and a network
                      of certified collision repair specialists accepted by all insurance companies. We manage
                      logistics from impact to delivery, reducing administration time for management and drivers,
                      enhancing operational efficiency, and minimising overall downtime.
                    </p>
                    <p>Customer satisfaction at DingGo is unparalleled, with the DingGo experience average 4.8/5 from
                      over 3000+ driver reviews.</p>
                    <h3>Nationwide Toll-Free Service:</h3>
                    <ul>
                      <li>Maximum caller response time: 60 seconds</li>
                      <li>Salvage and towing coordination (towing cost billed directly to your insurer)</li>
                      <li>Proactive timely communications coordinating all parties.</li>
                      <li>Replacement hire cars available in most locations, which can include drop off and pick up.
                      </li>
                      <li>Coordinated quality damage repairs to manufacturers’ specifications.</li>
                      <li>Quality damage repairs to your fleet' specifications (Inc signage, Decals & Stickers)</li>
                      <li>Daily progress tracking and proactive status updates</li>
                      <li>Customer log-in access to specialised accident management cloud-based portal</li>
                      <li>Windscreen/glass claim coordination and on-site replacements</li>
                      <li>Access to our world class Fleet Management Reporting</li>
                    </ul>
                    <h3>Health &amp; Safety</h3>
                    <p>
                      Protecting your mobile workforce is crucial. DingGo helps fleet operators comply with health &amp;
                      safety obligations, offering expertise in fit-for-purpose vehicle selection, maintenance safety,
                      and robust driver safety protocols.
                    </p>
                    <h3>Our technical team &amp; systems</h3>
                    <p>
                      The DingGo team, known as "The DingGo Team," boasts extensive insurance or trade-based
                      experience, including the collision repair sector. Our dedicated Account Managers provide
                      end-to-end support throughout the accident and collision repair process.
                    </p>

                    <p>State-of-the-art telephone technology and software systems ensure a prompt and dependable
                      response.</p>

                    <p>
                      Our cloud-based, real-time processes enable quick capture of all required information,
                      maximising customer service and response times. The platform ensures clarity, permanent
                      record-keeping, transparency, and auditability.
                    </p>

                    <p>Our operating system is completely developed in house and is hosted on shore in Australia on
                      AWS servers. It prioritises security and functionality, capable of handling an unlimited number
                      of cases simultaneously.</p>

                    <h3>Cost</h3>
                    <p>In addition to our core accident management offering, DingGo provides a range of service
                      enhancements, benefits, and reporting packages. Contact us now to understand your unique
                      requirements and develop a proposal for the ideal service solution, ensuring your business is
                      fleet fit.</p>

                    <h3>Talk to us now</h3>
                    <p>For a confidential, no-obligation discussion about your fleet requirements, contact <a href="mailto:sales@dinggo.com.au">sales@dinggo.com.au</a> now.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
};

export default AccidentManagementSME;